import { LocalizationKeys } from "./LocalizationKeys";

export const EnglishLocalizationMap: Record<LocalizationKeys, string> = {
    [LocalizationKeys.IntroStartButton]: "Start",
    [LocalizationKeys.IntroPageLoadingText]: "Loading...",
    [LocalizationKeys.RegistrationSubmitButton]: "Submit",
    [LocalizationKeys.RegistrationSkipButton]: "Skip",
    [LocalizationKeys.RegistrationFullName]: "*Full Name",
    [LocalizationKeys.RegistrationDisplayName]: "*Display Name",
    [LocalizationKeys.RegistrationPhoneNumber]: "*Phone Number",
    [LocalizationKeys.RegistrationEmail]: "*Email",
    [LocalizationKeys.RegistrationConsent]: "I would like to share my registration information with brands to receive offers and discounts",
    [LocalizationKeys.RegistrationPrizesButton]: "Prizes",
    [LocalizationKeys.RegistrationSelectImageButton]: "Upload image",
    [LocalizationKeys.WelcomeContinueButton]: "Continue",
    [LocalizationKeys.WelcomePrizesButton]: "Prizes",
    [LocalizationKeys.ExperiencePageStartButton]: "Start experience",
    [LocalizationKeys.ExperiencePageLoadingText]: "Loading experience content...",
    [LocalizationKeys.ExitModalText]: "Are you sure you want to exit experience?",
    [LocalizationKeys.ExitModalOkButton]: "Yes",
    [LocalizationKeys.ExitModalCancelButton]: "Cancel",
    [LocalizationKeys.PostGameRegistrationButton]: "Register",
    [LocalizationKeys.PostGameRegistrationText]: "*Register in order to see the leaderboard!",
    [LocalizationKeys.PostGameTryAgainButton]: "Try again",
    [LocalizationKeys.SelectionScreenDoneButton]: "Done",
    [LocalizationKeys.StatsComponentScore]: "Score",
    [LocalizationKeys.StatsComponentTime]: "Time",
};