import { LocalizationKeys } from "./LocalizationKeys";

export const HebrewLocalizationMap: Record<LocalizationKeys, string> = {
    [LocalizationKeys.IntroStartButton]: "התחל",
    [LocalizationKeys.IntroPageLoadingText]: "טוען...",
    [LocalizationKeys.RegistrationSubmitButton]: "שלח",
    [LocalizationKeys.RegistrationSkipButton]: "דלג",
    [LocalizationKeys.RegistrationFullName]: "שם מלא*",
    [LocalizationKeys.RegistrationDisplayName]: "כינוי*",
    [LocalizationKeys.RegistrationPhoneNumber]: "מספר טלאפון*",
    [LocalizationKeys.RegistrationEmail]: "מייל*",
    [LocalizationKeys.RegistrationConsent]: "אני מאשר שיתוף מידע רישום עם יוצר החוויה",
    [LocalizationKeys.RegistrationPrizesButton]: "פרסים",
    [LocalizationKeys.RegistrationSelectImageButton]: "העלאת תמונה",
    [LocalizationKeys.WelcomeContinueButton]: "המשך",
    [LocalizationKeys.WelcomePrizesButton]: "פרסים",
    [LocalizationKeys.ExperiencePageStartButton]: "התחל את החוויה",
    [LocalizationKeys.ExperiencePageLoadingText]: "טוען את תכני החוויה...",
    [LocalizationKeys.ExitModalText]: "האם אתה בטוח שברצונך לצאת מהחוויה?",
    [LocalizationKeys.ExitModalOkButton]: "כן",
    [LocalizationKeys.ExitModalCancelButton]: "ביטול",
    [LocalizationKeys.PostGameRegistrationButton]: "הרשמה",
    [LocalizationKeys.PostGameRegistrationText]: "הרשם כדי לראות את טבלת המובילים",
    [LocalizationKeys.PostGameTryAgainButton]: "נסה שוב",
    [LocalizationKeys.SelectionScreenDoneButton]: "!התחל",
    [LocalizationKeys.StatsComponentScore]: "ניקוד",
    [LocalizationKeys.StatsComponentTime]: "זמן",
};