import { useContext, useEffect, useRef, useState } from "react";
import { PlatformManager } from "../../../Templates/CustomTemplate/PlatformManager";
import { ARSurveyTemplate } from "../../../Templates/ARSurveyTemplate/ARSurveyTemplate";
import { CloseOutlined } from '@ant-design/icons'
import { CloseButtonModal } from "../../utils/CloseButtonModal";
import { IExperiencePage } from "../interfaces";
import { ServerManager } from "../../../Managers/ServerManager";
import { ITemplatesBasics } from "../../../Templates/TemplatesInterface";
import { servicesUrlsByName } from "../../../Configs/ServerConfig";
import { SoundManager } from "../../../Managers/SoundManager";
import { TriggerManager } from '../../../TriggerAction/TriggerManager';
import { ARManager } from "../../../Managers/ARManager"
import { ORBImageUI } from '../../../Managers/UIManager';
import { BrandThemeManager } from '../../../Managers/BrandThemeManager';
import { MenuTemplate } from "../../../Templates/MenuTemplate/MenuTemplate";
import { BaselBurgerShootersTemplate } from "../../../Templates/BaselBurgerShooters/BaselBurgerShooters";
import { ExperienceAnalyticsManager } from "../../../Managers/ExperienceAnalyticsManager";
import { Mixpanel } from "../../../Managers/AnalyticsClient/AnalyticsClient";
import { AnalyticsKeys, AnalyticsEventPropertyKeys } from "../../../Managers/AnalyticsClient/AnalyticsKeys";

import { LoadingPage } from "../loading-page/LoadingPage";
import { ExperienceType } from "../../../TriggerAction/Config";
import { AppStore } from "../../../app-store/store";
import { ErrorType } from "../error-page/errorTypes";
import { GridFullExperience, GridMetaTemplateProps } from "../../../Templates/GridMetaTemplate/GridExperienceObject";
import { GridMetaTemplate } from "../../../Templates/GridMetaTemplate/GridMetaTemplate";
import { IndoorDesignTemplate } from "../../../Templates/IndoorDesign/IndoorDesignTemplate";
import { PhotoAlbum } from "../../../Templates/PhotoAlbumTemplate/PhotoAlbum";
import { PortalTemplate } from "../../../Templates/PortalTemplate/PortalTemplate";
import { PageCloseButton } from "../exit-button/styled";
import { CustomizedButton } from "../welcome-page/styled";
import { useTranslation } from "react-i18next";
import { LocalizationKeys } from "../../../localization/LocalizationKeys";
import { TemplateClassType, TemplateSpecificUIRenderer } from "../../TemplatesRenderer/TemplateSpecificUIRenderer";
import { FullExperience } from "../../../ExperienceObjects/ExperienceObject";
import { TemplateUIOBjectsRenderer, UIObjectesRendererDelegate } from "../../TemplatesRenderer/TemplateUIObjectsRenderer";


export enum SessionStatus {
    ExperienceCompleted = 0,
    ExperienceNotCompleted = 1,
    ExperienceClosed = 2,
    ARSessionRequested = 3,
    ARSessionInitialized = 4,
    ARSessionStabilized = 5,
    ARSessionEnded = 6
}

export enum LottieNames {
    scanArea = "scanArea",
    loadingOrb = "ORB_Intro1",
    loading = "loading",
    failure = "failure",
    scanPlane = "scanPlane",
    tapScreen = "tapScreen",
    taskCompleted = "TaskCompleted",
    taskFailed = "TaskFailed",
    gameOver = "GameOver"
}

export enum GameResultType {
    award = "award",
    noAward = "noAward",
    taskComplete = "taskComplete",
    taskIncomplete = "taskIncomplete",
    taskQuit = "taskQuit"
}

export class GameStats {
    score: number
    level: number | null
    time: number | null

    constructor(score: number, level: number | null = null, time: number | null = null) {
        this.score = score
        this.level = level
        this.time = time
    }
}

export class ExperienceEndResponse {
    gameResultType: GameResultType
    allowRetry: boolean
    stats: GameStats | null
    analytics: Record<string, any> | null

    constructor(gameResultType: GameResultType, stats: GameStats, allowRetry: boolean = true, analytics: Record<string, any> | null = null) {
        this.gameResultType = gameResultType
        this.stats = stats
        this.allowRetry = allowRetry
        this.analytics = analytics
    }
}


export const ExperiencePage: React.FC<IExperiencePage> = ({ jsonObject, metadata, theme, onPageComplete }) => {

    const app = useRef<ITemplatesBasics | null>(null)
    const rendererRef = useRef<UIObjectesRendererDelegate | null>(null)

    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [didARStart, setDidARStart] = useState<boolean>(false)
    const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
    const { setErrorType, setErrorMessage, setShowErrorPage, experienceScore, userSession, globalTheme } = useContext(AppStore)
    const [templateClassType, setTemplateClassType] = useState<TemplateClassType | null>(null)
    const [stateTriggerManager, setStateTriggerManager] = useState<TriggerManager | null>(null);

    const { t } = useTranslation();

    var manager: ARManager | null = null;

    useEffect(() => {
        initiateAR();
        Mixpanel.track(AnalyticsKeys.ExperienceScreen, {
            [AnalyticsEventPropertyKeys.ExperienceID]: metadata.experienceID,
            [AnalyticsEventPropertyKeys.ExperienceType]: metadata.experienceType,
        })
        return () => {
            app.current = null
        }
    }, [])

    // useEffect(() => {
    //     if (isLoading == false) {
    //         experienceStartButtonTapped()
    //     }
    // }, [isLoading])

    const experienceStartButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Start"
        })
        app.current.launchAR()
        setDidARStart(true)
    }

    const experienceCloseButtonTapped = () => {
        Mixpanel.track(AnalyticsKeys.ButtonTapped, {
            [AnalyticsEventPropertyKeys.buttonID]: "Exit"
        })
        sessionStatusChanged(SessionStatus.ExperienceClosed)
    }

    const initiateAR = async () => {
        let template = await createTemplateInstance()
        setTemplateClassType(template)

        Mixpanel.track(AnalyticsKeys.TemplateInitialized, { [AnalyticsEventPropertyKeys.ExperienceType]: metadata.experienceType })
        app.current = template
        setIsLoading(false)
    }

    const createTemplateInstance = async (): Promise<TemplateClassType> => {
        const triggerManager = new TriggerManager()
        setStateTriggerManager(triggerManager)
        // uiManager = new UIManager(triggerManager, setStatsParams, setScore, setTimeDelta, showInstructionsLottie.bind(this))
        const soundManager = new SoundManager()
        const userID: string = userSession
        const experienceAnalyticsManager = new ExperienceAnalyticsManager(metadata, userID)
        var brandThemeManager: BrandThemeManager | null = null
        if (theme) {
            brandThemeManager = new BrandThemeManager(theme)
        }
        manager = new ARManager(triggerManager, brandThemeManager, sessionStatusChanged.bind(this))
        switch (metadata.experienceType) {
            case ExperienceType.SURVEY: {
                const surveyInput = await ARSurveyTemplate.parseInput(jsonObject)
                const surveyTemplate = new ARSurveyTemplate(surveyInput, triggerManager, manager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                return surveyTemplate
            }
            case ExperienceType.SHOOTERSTEMPLATE:
            case ExperienceType.BUILDYOUROWN: {
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject)
                const platformManager = new PlatformManager(fullExperience, triggerManager, manager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                return platformManager
            }
            case ExperienceType.MENUTEMPLATE: {
                // Load template input
                const menuConfig = await MenuTemplate.parseInput(jsonObject["input"])

                // Load aux full experience
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject["auxData"]["backgroundExperienceJSON"])
                // // Initialize template. In this case, initialize it as an instance of PlatformManager, then inject other properties
                const menuTemplate = new MenuTemplate(fullExperience, triggerManager, manager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                menuTemplate.config = menuConfig
                // menuTemplate.menuListComponentHandler = menuListComponentHandler

                return menuTemplate
            }
            case ExperienceType.BASELBURGERSHOOTERS: {
                // Load template input
                const config = await BaselBurgerShootersTemplate.parseInput(jsonObject["input"])

                // Load aux full experience
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject["auxData"]["backgroundExperienceJSON"])
                // // Initialize template. In this case, initialize it as an instance of PlatformManager, then inject other properties
                const template = new BaselBurgerShootersTemplate(fullExperience, triggerManager, manager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                template.config = config
                // template.itemSelectionListComponentHandler = itemSelectedComponentHandler

                return template
            }
            case ExperienceType.INDOORDESIGN: {
                // Load template input
                const config = await IndoorDesignTemplate.parseInput(jsonObject["input"])

                // Load aux full experience
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject["auxData"]["backgroundExperienceJSON"])
                // // Initialize template. In this case, initialize it as an instance of PlatformManager, then inject other properties
                const template = new IndoorDesignTemplate(fullExperience, triggerManager, manager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                template.config = config

                return template
            }
            case ExperienceType.PHOTOALBUM: {
                // Load template input
                const config = await PhotoAlbum.parseInput(jsonObject["input"])

                // Load aux full experience
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject["auxData"]["backgroundExperienceJSON"])
                // // Initialize template. In this case, initialize it as an instance of PlatformManager, then inject other properties
                const template = new PhotoAlbum(fullExperience, triggerManager, manager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                template.config = config

                return template
            }
            case ExperienceType.PORTALTEMPLATE: {
                // Load template input
                const config = await PortalTemplate.parseInput(jsonObject["input"])

                // Load aux full experience
                const fullExperience = await PlatformManager.loadCustomExperience(jsonObject["auxData"]["backgroundExperienceJSON"])
                // // Initialize template. In this case, initialize it as an instance of PlatformManager, then inject other properties
                const template = new PortalTemplate(fullExperience, triggerManager, manager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))
                template.config = config

                return template
            }
            case ExperienceType.GRIDTEMPLATE: {
                const gridProps = new GridMetaTemplateProps()
                const imageElement = new ORBImageUI({
                    "tag": "image1",
                    "type": "image",
                    "locX": 0.5,
                    "locY": 0.5,
                    "height": 70,
                    "width": 70,
                    "imageUrl": "https://firebasestorage.googleapis.com/v0/b/arplatform-99ab9.appspot.com/o/Images%2FShootingButton.png?alt=media&token=1d29048b-4921-4a4e-a496-fd9e365166ac"
                })
                imageElement.loadData(() => { })
                const uiElements = [imageElement]
                const gridFullExperience = new GridFullExperience(gridProps, uiElements)

                const template = new GridMetaTemplate(gridFullExperience, triggerManager, manager, soundManager, brandThemeManager, experienceAnalyticsManager, sessionStatusChanged.bind(this))

                return template
            }
            default:
                setErrorType(ErrorType.BadIDType)
                setErrorMessage(`${metadata.experienceType} bad experience type!`)
                setShowErrorPage(true)
        }
    }

    const sessionStatusChanged = (status: SessionStatus) => {
        // console.log("ExperiencePage: session status changed", status)
        switch (status) {
            case SessionStatus.ExperienceCompleted:
            // Fall-through
            case SessionStatus.ExperienceNotCompleted:
            // Fall-through
            case SessionStatus.ExperienceClosed:
                experienceOver(status)
                break;
            case SessionStatus.ARSessionRequested:
                // console.log("AR SESSION STATUS: REQUESTED")
                break;
            case SessionStatus.ARSessionInitialized:
                rendererRef.current?.placeLottie(LottieNames.scanArea)
                // console.log("AR SESSION STATUS: INITIALIZED")
                break;
            case SessionStatus.ARSessionStabilized:
                // console.log("AR SESSION STATUS: STABILIZED")
                rendererRef.current?.placeLottie(null)
                Mixpanel.track(AnalyticsKeys.ARStabilized)

                app.current.setRendererRef(rendererRef.current)
                manager.setRendererRef(rendererRef.current)
                app.current.startExperience()
                // setExperienceStarted(true)
                break;
            case SessionStatus.ARSessionEnded:
                // console.log("AR SESSION STATUS: ENDED")
                experienceOver(SessionStatus.ExperienceClosed)
                break;
        }
    }

    const experienceOver = (sessionStatus: SessionStatus) => {
        // If the app.current is null it means that this function has already been called.
        // Example: in case the user exits via the button, then experience over is called, then sessionStatusChanged is called with ARSessionEnded, which again calls experienceOver.
        if (app.current === null) {
            return
        }

        Mixpanel.track(AnalyticsKeys.ExperienceDone, { [AnalyticsEventPropertyKeys.ExperienceDoneStatus]: String(sessionStatus) })
        // handleExperienceAnalytics(sessionStatus)
        // TODO: (Ibrahim) better define session status
        handleExperienceAnalytics(SessionStatus.ExperienceCompleted)
        app.current.cleanup()
        app.current = null;

        const localStorageScore = Number(localStorage.getItem('HitzScore'))
        const gameStats = new GameStats(isNaN(localStorageScore) ? experienceScore : localStorageScore)
        // const gameStats = new GameStats(rendererRef.current?.fetchScore() ?? -1)
        switch (sessionStatus) {
            case SessionStatus.ExperienceCompleted:
                var response = new ExperienceEndResponse(GameResultType.taskComplete, gameStats)
                onPageComplete(response)
                break;
            case SessionStatus.ExperienceNotCompleted:
                var response = new ExperienceEndResponse(GameResultType.taskIncomplete, gameStats)
                onPageComplete(response)
                break;
            case SessionStatus.ExperienceClosed:
                var response = new ExperienceEndResponse(GameResultType.taskQuit, gameStats)
                onPageComplete(response)
                break;
        }
    }

    const handleExperienceAnalytics = (sessionStatus: SessionStatus) => {
        const analytics = app.current.prepareFullAnalytics(sessionStatus)
        console.log("ANALYTICSSS", analytics)
        // const jsonString = JSON.stringify(analytics)
        ServerManager.uploadJsonFile(analytics, servicesUrlsByName.analytics, `/analytics`)
    }

    return (
        <>
            <div>
                <div id="overlay-container">
                    {
                        templateClassType && <TemplateSpecificUIRenderer template={templateClassType} />
                    }
                    {
                        stateTriggerManager && <TemplateUIOBjectsRenderer ref={rendererRef} triggerManager={stateTriggerManager} />
                    }
                    <div>
                        <canvas
                            id="touch-canvas"
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                zIndex: -1,
                            }}
                        />
                    </div>
                    {
                        openConfirmModal ?
                            <CloseButtonModal closeModal={() => setOpenConfirmModal(false)} handleCloseExperience={() => experienceCloseButtonTapped()} /> : null
                    }
                    <PageCloseButton
                        onClick={() => setOpenConfirmModal(true)}
                        shape="circle"
                        icon={<CloseOutlined />}
                    />
                </div>
                <div
                    style={{
                        ...(jsonObject["AndroidExperiencePageBackgroundImage"] && {
                            backgroundImage: `url(${jsonObject["AndroidExperiencePageBackgroundImage"]})`,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            height: '100vh',
                            width: '100vw',
                        }),
                    }}
                >
                    {
                        !didARStart && isLoading ?
                            <LoadingPage lottieFileName={LottieNames.loading} loadingText={t(LocalizationKeys.ExperiencePageLoadingText)} /> :
                            <CustomizedButton
                                style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}
                                primaryColor={theme.primaryColor}
                                secondaryColor={theme.secondaryColor}
                                onClick={() => experienceStartButtonTapped()}>
                                {t(LocalizationKeys.ExperiencePageStartButton)}
                            </CustomizedButton>
                    }
                </div>
            </div>
        </>
    )
}

{/* {
    showJoystickButton && <div style={{
        position: 'fixed',
        top: '50%',
        right: '20px',
        display: 'flex',
        alignItems: 'center'
    }}>
        <Button type="primary" icon={<CodepenCircleOutlined />} onClick={joystickButtonTapped} />
    </div>
} */}
{/* {
    showJoystick && <JoystickController
        rotateX={rotateX}
        rotateY={rotateY}
        rotateZ={rotateZ}
        moveX={moveX}
        moveY={moveY}
        moveZ={moveZ}
        onChange={joystickChange}
    />
} */}
{/* {
    statsParams.status &&
    <StatsComponent
        seconds={statsParams.initTime}
        score={score}
        timeDelta={timeDelta}
        callback={() => statsParams.callBackFun()}
        scoreChangeCallback={(score: number) => statsParams.scoreChangeCallback(score)} />
} */}
{/* {
    lottieData !== null &&
    <LottieContainer id="lottie-container">
        <Lottie animationData={lottieData} />
    </LottieContainer>
} */}

// const menuListComponentHandler = (option: ComponentOptions, list: MenuItem[], menuTheme: MenuListViewTheme, callback?: (id: string | number, index: number) => void) => {
//     switch (option) {
//         case ComponentOptions.add:
//             {
//                 if (list.length && callback) {
//                     const menuWrapper = document.createElement('div')
//                     menuWrapper.className = 'menu-wrapper'
//                     document.getElementById('overlay-container').appendChild(menuWrapper)
//                     createRoot(menuWrapper).render(<MenuListView menuList={list} menuTheme={menuTheme} onItemClick={callback} />)
//                 }
//                 break;

//             }
//         case ComponentOptions.remove:
//             {
//                 const menuWrapper = document.querySelector('.menu-wrapper');
//                 if (menuWrapper) {
//                     ReactDOM.unmountComponentAtNode(menuWrapper);
//                     menuWrapper.remove();
//                 }
//                 break;
//             }
//         case ComponentOptions.present:
//             {
//                 const menuWrapper = document.querySelector('.menu-wrapper') as HTMLElement | null;;
//                 if (menuWrapper) {
//                     menuWrapper.style.display = 'block';
//                 }
//                 break;
//             }
//         case ComponentOptions.hide:
//             {
//                 const menuWrapper = document.querySelector('.menu-wrapper') as HTMLElement | null;;
//                 if (menuWrapper) {
//                     menuWrapper.style.display = 'none';
//                 }
//                 break;
//             }
//     }
// }

// const itemSelectedComponentHandler = (option: ComponentOptions, list: BaselBurgerListItem[], theme: ShootersListViewTheme, callback?: (id: string | number, index: number) => void) => {
//     switch (option) {
//         case ComponentOptions.add:
//             {
//                 if (list.length && callback) {
//                     const wrapper = document.createElement('div')
//                     wrapper.className = 'item-selected-wrapper'
//                     document.getElementById('overlay-container').appendChild(wrapper)
//                     theme.globalTheme = globalTheme
//                     createRoot(wrapper).render(<BaselBurgerSelectionScreen list={list} theme={theme} onItemClick={callback} />)
//                 }
//                 break;

//             }
//         case ComponentOptions.remove:
//             {
//                 const wrapper = document.querySelector('.item-selected-wrapper');
//                 if (wrapper) {
//                     ReactDOM.unmountComponentAtNode(wrapper);
//                     wrapper.remove();
//                 }
//                 break;
//             }
//         case ComponentOptions.present:
//             {
//                 const wrapper = document.querySelector('.item-selected-wrapper') as HTMLElement | null;;
//                 if (wrapper) {
//                     wrapper.style.display = 'block';
//                 }
//                 break;
//             }
//         case ComponentOptions.hide:
//             {
//                 const wrapper = document.querySelector('.item-selected-wrapper') as HTMLElement | null;;
//                 if (wrapper) {
//                     wrapper.style.display = 'none';
//                 }
//                 break;
//             }
//     }
// }

// const joystickButtonTapped = () => {
//     setShowJoystick((prev) => {
//         return (!prev)
//     })
// }

// const joystickChange = (joystickIdx: JoystickSliderOptions) => (value) => {
//     // console.log("@@@ changeee", joystickIdx, value)
// }

// const rotateX: IJoystickSlider = {
//     minValue: -90,
//     maxValue: 90,
//     length: 200,
// };
// const rotateY: IJoystickSlider = {
//     minValue: -90,
//     maxValue: 90,
//     length: 200,
// };
// const rotateZ: IJoystickSlider = {
//     minValue: -90,
//     maxValue: 90,
//     length: 200,
// };
// const moveX: IJoystickSlider = {
//     minValue: -100,
//     maxValue: 100,
//     length: 200,
// };
// const moveY: IJoystickSlider = {
//     minValue: -100,
//     maxValue: 100,
//     length: 200,
// };
// const moveZ: IJoystickSlider = {
//     minValue: -100,
//     maxValue: 100,
//     length: 200,
// };